body {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

::selection {
  background: #C9C9C8;
}

h1, h2, p {
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.header-brand {
  margin-left: 300px;
}

.header-buttons {
  display: flex;
}

.header-buttons li {
  margin-right: 30px;
}

.header-buttons li:last-child {
  margin-right: 300px;
}

.header-buttons li a {
  text-decoration: none;
}

.header-button {
  background-color: #F6F7F8;
  border: none;
  border-radius: 8px;
  padding: 12px 15px;
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #313132;
  font-size: 14px;
  font-weight: 400;
  transition: transform 0.3s ease;
}

.header-button:hover {
  transform: scale(1.05);
}

.header-button img {
  margin-right: 10px;
}

.main-block {
  background: #F6F7F8;
  display: flex;
  justify-content: space-between;
  padding: 40px 300px;
  margin-left: auto;
  margin-right: auto;
}

.main-block__image {
  max-width: 100%;
  height: auto;
}

.main-block__content h1 {
  color: #313132;
  font-size: 64px;
  font-weight: 700;
  line-height: normal;
}

.main-block__content p {
  color: #313132;
  font-size: 20px;
  font-weight: 400;
  padding: 40px 0;
}

.main-block__content button {
  width: 100%;
  background-color: #313132;
  border: none;
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  transition: transform 0.3s ease;
}

.main-block__content button:hover {
  transform: scale(1.02);
}

.companies-block {
  display: block;
  padding: 40px 500px;
}

.companies-block h2 {
  color: #313132;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}

.vacancies-block {
  background: #F6F7F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 300px;
}

.vacancies-block h2 {
  color: #313132;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 15px;
}

.vacancies-block p {
  color: #313132;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
}

.vacancies-block__offers .offer {
  margin: 0 auto 30px auto;
  padding: 50px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  position: relative;
}

.vacancies-block__offers .offer:last-child {
  margin-bottom: 0;
}

.vacancies-block__offers .offer-logo {
  border-radius: 15px;
  width: 65px;
  height: 65px;
}

.vacancies-block__offers .offer-text {
  margin-left: 35px;
  max-width: 80%;
}

.vacancies-block__offers .offer-name {
  color: #313132;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
  max-width: 80%;
}

.vacancies-block__offers .offer-desc {
  color: #313132;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  max-width: 80%;
}

.vacancies-block__offers .offer-content {
  text-align: right;
}

.vacancies-block__offers .offer-price {
  color: #313132;
  font-size: 24px;
  font-weight: 700;
  text-align: right;
  margin: 0;
  padding: 0;
}

.offer-button {
  background-color: #F6F7F8;
  border: none;
  border-radius: 8px;
  padding: 12px 15px;
  cursor: pointer;
  color: #313132;
  font-size: 14px;
  font-weight: 400;
  transition: transform 0.3s ease;
  position: absolute;
  bottom: 50px;
  right: 50px;
  display: flex;
}

.offer-button:hover {
  transform: scale(1.05);
}

.offer-button img {
  margin-left: 10px;
}

.about-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  background-image: url("./img/about-us-image.png");
}

.about-block h2 {
  color: #FFF;
  font-size: 32px;
  font-weight: 700;
}

.about-block p {
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-top: 40px;
}

.contacts-block {
  background: #F6F7F8;
  align-items: center;
  padding: 73px 300px 40px 300px;
}

.contacts-block__content {
  display: flex;
  background: #fff;
  border-radius: 15px;
  padding: 50px;
  justify-content: space-between;
  position: relative;
}

.contacts-block__content .info-text h2 {
  color: #313132;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 40px;
}

.contacts-block__content .info-text p {
  color: #313132;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 40px;
}

.contacts-block__content .social-buttons {
  display: flex;
  flex-direction: column;
}

.contacts-block__content .social-buttons button {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
}

.contacts-block__content .social-buttons img {
  padding-right: 10px;
}

.btn-vk {
  background: #0077FF;
  transition: transform 0.3s ease;
}

.btn-tg {
  background: #2DA7DB;
  transition: transform 0.3s ease;
}

.btn-email {
  background: #313132;
  transition: transform 0.3s ease;
}

.btn-vk:hover, .btn-tg:hover, .btn-email:hover {
  transform: scale(1.05);
}

.contacts-block__content .mobile-preview {
  display: block;
  position: absolute;
  right: 50px;
  bottom: -3px;
}

footer {
  padding: 25px 0;
  text-align: center;
  color: #313132;
  font-size: 12px;
  font-weight: 500;
}

@media screen and (min-width: 1368px) and (max-width: 1919px) {
  .header-brand {
    margin-left: 200px;
  }

  .header-buttons li:last-child {
    margin-right: 200px;
  }

  .main-block {
    padding: 40px 200px;
  }

  .main-block__content h1 {
    font-size: 50px;
  }

  .main-block__content p {
    font-size: 16px;
    padding: 40px 0;
  }

  .main-block__content button {
    padding: 18px;
  }

  .companies-block {
    display: block;
    padding: 40px 200px;
  }

  .companies-block h2 {
    font-size: 30px;
  }

  .vacancies-block {
    padding: 40px 200px;
  }

  .vacancies-block h2 {
    font-size: 30px;
  }

  .vacancies-block p {
    font-size: 16px;
  }

  .vacancies-block__offers .offer-text {
    margin-left: 35px;
    max-width: 80%;
  }

  .vacancies-block__offers .offer-name {
    font-size: 22px;
    margin-bottom: 30px;
    max-width: 80%;
  }

  .vacancies-block__offers .offer-desc {
    font-size: 14px;
    max-width: 80%;
  }

  .vacancies-block__offers .offer-price {
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    top: 40px;
    right: 40px;
  }

  .vacancies-block__offers .offer {
    padding: 40px;
  }

  .offer-button {
    font-size: 14px;
    bottom: 40px;
    right: 40px;
  }

  .about-block {
    padding: 60px 0;
  }

  .about-block h2 {
    font-size: 30px;
  }

  .about-block p {
    font-size: 16px;
  }

  .contacts-block {
    padding: 73px 200px 40px 200px;
  }

  .contacts-block__content {
    padding: 70px;
  }

  .contacts-block__content .info-text h2 {
    font-size: 30px;
    padding-bottom: 30px;
  }

  .contacts-block__content .info-text p {
    font-size: 16px;
    padding-bottom: 30px;
  }

  .contacts-block__content .social-buttons button {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .contacts-block__content .mobile-preview {
    right: 40px;
  }

  footer {
    padding: 25px 0;
    text-align: center;
    color: #313132;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1368px) {
  .header-brand {
    margin-left: 200px;
  }

  .header-buttons li:last-child {
    margin-right: 200px;
  }

  .header-button {
    font-size: 12px;
    font-weight: 400;
    transition: transform 0.3s ease;
    padding: 10px 12px;
  }

  .header-brand img {
    max-width: 80%;
    height: auto;
  }

  .main-block {
    padding: 40px 200px;
  }

  .main-block__content h1 {
    font-size: 30px;
  }

  .main-block__image img {
    max-width: 100%;
    height: auto;
  }

  .main-block__content p {
    font-size: 14px;
    padding: 20px 0;
  }

  .main-block__content button {
    padding: 12px;
    font-size: 12px;
  }

  .companies-block {
    display: block;
    padding: 40px 200px;
  }

  .companies-block h2 {
    font-size: 28px;
  }

  .vacancies-block {
    padding: 40px 200px;
  }

  .vacancies-block h2 {
    font-size: 28px;
  }

  .vacancies-block p {
    font-size: 14px;
  }

  .vacancies-block__offers .offer-text {
    margin-left: 35px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-name {
    font-size: 22px;
    margin-bottom: 30px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-desc {
    font-size: 14px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-price {
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    top: 40px;
    right: 40px;
  }

  .vacancies-block__offers .offer {
    padding: 40px;
  }

  .offer-button {
    font-size: 14px;
    bottom: 40px;
    right: 40px;
  }

  .about-block {
    padding: 60px 0;
  }

  .about-block h2 {
    font-size: 28px;
  }

  .about-block p {
    font-size: 14px;
  }

  .contacts-block {
    padding: 73px 200px 40px 200px;
  }

  .contacts-block__content {
    padding: 35px 35px 0 35px;
  }

  .contacts-block__content .info-text h2 {
    font-size: 24px;
    padding-bottom: 30px;
  }

  .contacts-block__content .info-text p {
    font-size: 14px;
    padding-bottom: 30px;
  }

  .contacts-block__content .social-buttons button {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .contacts-block__content .mobile-preview {
    position: relative;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    display:flex;
    align-items:center;
    justify-content: flex-end;
  }

  .mobile-preview img {
    max-width: 90%;
    height: auto;
  }


  footer {
    padding: 25px 0;
    text-align: center;
    color: #313132;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .header-brand {
    margin-left: 100px;
  }

  .header-buttons li:last-child {
    margin-right: 100px;
  }

  .header-button {
    font-size: 12px;
    font-weight: 400;
    transition: transform 0.3s ease;
    padding: 10px 12px;
  }

  .header-brand img {
    max-width: 80%;
    height: auto;
  }

  .main-block {
    padding: 40px 100px;
  }

  .main-block__content h1 {
    font-size: 30px;
  }

  .main-block__image img {
    max-width: 100%;
    height: auto;
  }

  .main-block__content p {
    font-size: 14px;
    padding: 20px 0;
  }

  .main-block__content button {
    padding: 12px;
    font-size: 12px;
  }

  .companies-block {
    display: block;
    padding: 40px 100px;
  }

  .companies-block h2 {
    font-size: 28px;
  }

  .vacancies-block {
    padding: 40px 100px;
  }

  .vacancies-block h2 {
    font-size: 28px;
  }

  .vacancies-block p {
    font-size: 14px;
  }

  .vacancies-block__offers .offer-text {
    margin-left: 35px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-name {
    font-size: 22px;
    margin-bottom: 30px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-desc {
    font-size: 14px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-price {
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    top: 40px;
    right: 40px;
  }

  .vacancies-block__offers .offer {
    padding: 40px;
  }

  .offer-button {
    font-size: 14px;
    bottom: 40px;
    right: 40px;
  }

  .about-block {
    padding: 60px 0;
  }

  .about-block h2 {
    font-size: 28px;
  }

  .about-block p {
    font-size: 14px;
  }

  .contacts-block {
    padding: 73px 100px 40px 100px;
  }

  .contacts-block__content {
    padding: 35px 35px 0 35px;
  }

  .contacts-block__content .info-text h2 {
    font-size: 24px;
    padding-bottom: 30px;
  }

  .contacts-block__content .info-text p {
    font-size: 14px;
    padding-bottom: 30px;
  }

  .contacts-block__content .social-buttons button {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .contacts-block__content .mobile-preview {
    position: relative;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    display:flex;
    align-items:center;
    justify-content: flex-end;
  }

  .mobile-preview img {
    max-width: 90%;
    height: auto;
  }


  footer {
    padding: 25px 0;
    text-align: center;
    color: #313132;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (min-width: 700px) and (max-width: 999px) {
  .header-brand {
    margin-left: 50px;
  }

  .header-buttons li:last-child {
    margin-right: 50px;
  }

  .header-button {
    font-size: 12px;
    font-weight: 400;
    transition: transform 0.3s ease;
    padding: 10px 12px;
  }

  .header-brand img {
    max-width: 70%;
    height: auto;
  }

  .main-block {
    padding: 40px 50px;
    display: block;
  }

  .main-block__content h1 {
    font-size: 30px;
  }

  .main-block__image {
    display: flex;
    justify-content: center;
  }

  .main-block__image img {
    padding-bottom: 20px;
    max-width: 80%;
    height: auto;
  }

  .main-block__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-block__content p {
    font-size: 14px;
    padding: 20px 0;
  }

  .main-block__content button {
    max-width: 400px;
    padding: 14px;
    font-size: 14px;
  }

  .companies-block {
    display: block;
    padding: 40px 50px;
  }

  .companies-block h2 {
    font-size: 26px;
  }

  .vacancies-block {
    padding: 20px 50px;
  }

  .vacancies-block h2 {
    font-size: 26px;
  }

  .vacancies-block p {
    font-size: 14px;
  }

  .vacancies-block__offers .offer-text {
    margin-left: 35px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-name {
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 70%;
  }

  .vacancies-block__offers .offer-desc {
    font-size: 12px;
    max-width: 60%;
  }

  .vacancies-block__offers .offer-price {
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .vacancies-block__offers .offer {
    padding: 20px;
  }

  .offer-button {
    font-size: 12px;
    bottom: 20px;
    right: 20px;
  }

  .about-block {
    padding: 40px 0;
  }

  .about-block h2 {
    font-size: 26px;
  }

  .about-block p {
    font-size: 14px;
  }

  .contacts-block {
    padding: 40px;
  }

  .contacts-block__content {
    padding: 20px 20px 0 20px;
  }

  .contacts-block__content .info-text h2 {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .contacts-block__content .info-text p {
    font-size: 14px;
    padding-bottom: 30px;
  }

  .contacts-block__content .social-buttons button {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .contacts-block__content .mobile-preview {
    position: relative;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    display:flex;
    align-items:center;
    justify-content: flex-end;
  }

  .mobile-preview img {
    max-width: 80%;
    height: auto;
  }

  .contacts-block__content .social-buttons button {
    text-align: left;
    padding: 8px;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
  }

  .social-buttons a button img {
    width: 20px;
    height: auto;
  }

  footer {
    padding: 25px 0;
    text-align: center;
    color: #313132;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (min-width: 533px) and (max-width: 699px) {
  .header {
    display: flex;
    justify-content: center;
  }

  .header-brand {
    margin-left: 0;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
  }

  .header-brand img {
    display: flex;
    justify-content: center;
    max-width: 70%;
    height: auto;
  }


  .header-buttons {
    display: none;
  }

  .main-block {
    padding: 40px 50px;
    display: block;
  }

  .main-block__content h1 {
    font-size: 25px;
    text-align: center;
  }

  .main-block__image {
    display: flex;
    justify-content: center;
  }

  .main-block__image img {
    padding-bottom: 20px;
    max-width: 80%;
    height: auto;
  }

  .main-block__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-block__content p {
    font-size: 14px;
    padding: 20px 0;
  }

  .main-block__content button {
    max-width: 250px;
    padding: 12px;
    font-size: 14px;
  }

  .companies-block {
    display: block;
    padding: 40px 50px;
  }

  .companies-block h2 {
    font-size: 22px;
  }

  .vacancies-block {
    padding: 40px 50px;
  }

  .vacancies-block h2 {
    font-size: 22px;
  }

  .vacancies-block p {
    font-size: 14px;
  }

  .vacancies-block__offers .offer {
    display: flex;
    flex-direction: column;
  }

  .vacancies-block__offers .offer .offer-logo {
    width: 40px;
    height: 40px;
  }

  .vacancies-block__offers .offer-text {
    font-size: 14px;
    padding-top: 20px;
    margin-left: 0;
    max-width: 100%;
  }


  .vacancies-block__offers .offer-name {
    font-size: 18px;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .vacancies-block__offers .offer-desc {
    font-size: 14px;
    max-width: 100%;
  }

  .vacancies-block__offers .offer-price {
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .vacancies-block__offers .offer {
    padding: 20px;
  }

  .offer-button {
    margin-top: 20px;
    position: relative;
    font-size: 14px;
    bottom: 0;
    right: 0;
  }
  .offer a {
    display: flex;
    justify-content: flex-end;
  }

  .about-block {
    padding: 20px 0;
  }

  .about-block h2 {
    font-size: 22px;
  }

  .about-block p {
    margin-top: 20px;
    font-size: 14px;
    padding: 0 40px;
  }

  .contacts-block {
    padding: 40px;
  }

  .contacts-block__content {
    padding: 20px 20px 5px 20px;
    display: flex;
    justify-content: center;
  }

  .contacts-block__content .info-text h2 {
    font-size: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .contacts-block__content .info-text p {
    font-size: 14px;
    padding-bottom: 30px;
    text-align: center;
  }

  .contacts-block__content .social-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .contacts-block__content .social-buttons button {
    text-align: left;
    padding: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .contacts-block__content .mobile-preview {
    display: none;
  }

  .social-buttons a button img {
    width: 20px;
    height: auto;
  }

  footer {
    padding: 25px 0;
    text-align: center;
    color: #313132;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (min-width: 350px) and (max-width: 532px) {
  .header {
    display: flex;
    justify-content: center;
  }

  .header-brand {
    margin-left: 0;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
  }

  .header-brand img {
    display: flex;
    justify-content: center;
    max-width: 70%;
    height: auto;
  }

  .header-buttons {
    display: none;
  }

  .main-block {
    padding: 20px 20px;
    display: block;
  }

  .main-block__content h1 {
    font-size: 25px;
    text-align: center;
  }

  .main-block__image {
    display: flex;
    justify-content: center;
  }

  .main-block__image img {
    padding-bottom: 20px;
    max-width: 80%;
    height: auto;
  }

  .main-block__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-block__content p {
    font-size: 12px;
    padding: 20px 0;
    text-align: center;
  }

  .main-block__content button {
    max-width: 250px;
    padding: 12px;
    font-size: 12px;
  }

  .companies-block {
    display: block;
    padding: 20px 20px;
  }

  .companies-block h2 {
    font-size: 20px;
  }

  .vacancies-block {
    padding: 20px 20px;
  }

  .vacancies-block h2 {
    font-size: 20px;
  }

  .vacancies-block p {
    font-size: 12px;
  }

  .vacancies-block__offers .offer {
    display: flex;
    flex-direction: column;
  }

  .vacancies-block__offers .offer .offer-logo {
    width: 40px;
    height: 40px;
  }

  .vacancies-block__offers .offer-text {
    padding-top: 20px;
    margin-left: 0;
    max-width: 100%;
  }

  .vacancies-block__offers .offer-name {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .vacancies-block__offers .offer-desc {
    font-size: 12px;
    max-width: 100%;
  }

  .vacancies-block__offers .offer-price {
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .vacancies-block__offers .offer {
    padding: 20px;
  }

  .offer-button {
    margin-top: 20px;
    position: relative;
    font-size: 12px;
    bottom: 0;
    right: 0;
  }

  .offer a {
    display: flex;
    justify-content: flex-end;
  }

  .about-block {
    padding: 20px 0;
  }

  .about-block h2 {
    font-size: 20px;
  }

  .about-block p {
    margin-top: 20px;
    font-size: 12px;
    padding: 0 40px;
  }

  .contacts-block {
    padding: 20px;
  }

  .contacts-block__content {
    padding: 20px 20px 5px 20px;
    display: flex;
    justify-content: center;
  }

  .contacts-block__content .info-text h2 {
    font-size: 18px;
    padding-bottom: 20px;
    text-align: center;
  }

  .contacts-block__content .info-text p {
    font-size: 12px;
    padding-bottom: 30px;
    text-align: center;
  }

  .contacts-block__content .social-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contacts-block__content .social-buttons button {
    text-align: left;
    padding: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .contacts-block__content .mobile-preview {
    display: none;
  }

  .social-buttons a button img {
    width: 20px;
    height: auto;
  }

  footer {
    padding: 25px 0;
    text-align: center;
    color: #313132;
    font-size: 12px;
    font-weight: 500;
  }
}